import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { Section, Button, Title, Text, Box } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import CTA from "../sections/common/CTA";
import { device } from "../utils";

const WorkSingle = () => {
  return (
    <>
      <PageWrapper darkTheme>
        <Section hero>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Box>
                  <Text variant="tag">Poster Projects</Text>
                  <Title className="my-4">
                    Triptych Poster Series - Anchor Baby
                  </Title>
                </Box>
              </Col>
            </Row>

            <Box pt={["4rem", null, null, "6.25rem"]}>
              <img
                src="/projects/triptych/WallMockup.jpg?nf_resize=fit&w=1140"
                alt=""
                className="img-fluid w-100"
              />
            </Box>
          </Container>
        </Section>

        <div className="mt-lg-3">
          <Container>
            <Row className="justify-content-center ">
              <Col lg="8">
                <Box>
                  <Text
                    variant="p"
                    css={`
                      max-width: 750px;
                    `}
                  >
                    This is a three part poster series where I explore the life
                    and view of an Anchor Baby. Specifically a Mexican Anchor
                    Baby. An “anchor baby” is the term used for children born to
                    noncitizen parents in the hopes that it will help them
                    secure legal citizenship. I myself am an anchor baby. My
                    family, (mother, father, sister, and two brothers) were all
                    born in Mexico. At one point they all immigrated to the U.S.
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
          <div className="mt-lg-3 pt-5">
            <Container>
              <Row className="justify-content-center ">
                <Col lg="8" className="mb-4 mb-lg-0">
                  <Text variant="tag">Tools Used</Text>
                  <Title variant="cardBig" className="mt-3">
                    Photoshop, Illustrator
                  </Title>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        <Section className="mt-lg-5 pb-0">
          <Container>
            <Row>
              <Col xs="4" className="mb-5 mb-lg-0 pr-lg-4">
                <img
                  src="/projects/triptych/Poster1.jpg?nf_resize=fit&w=531"
                  alt=""
                  className="img-fluid w-100"
                />
              </Col>
              <Col xs="4" className="mb-5 mb-lg-0 pr-lg-4">
                <img
                  src="/projects/triptych/poster2.jpg?nf_resize=fit&w=531"
                  alt=""
                  className="img-fluid w-100"
                />
              </Col>
              <Col xs="4" className="mb-5 mb-lg-0 pr-lg-4">
                <img
                  src="/projects/triptych/poster3.jpg?nf_resize=fit&w=531"
                  alt=""
                  className="img-fluid w-100"
                />
              </Col>
            </Row>
          </Container>
        </Section>

        <Section bg="dark" className="pt-12">
          <Container>
            <div className="text-center mt-lg-5">
              <Text variant="tag" className="mb-1" color="lightShade">
                Next Project
              </Text>
              <Link to="/dh-portfolio-book">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                `}
                >
                  Daniella Herrera Portfolio Book
                </Button>
              </Link>
            </div>
          </Container>
        </Section>
        <Box py={4}>
          <CTA />
        </Box>
      </PageWrapper>
    </>
  );
};
export default WorkSingle;
